import { render } from "./main.vue?vue&type=template&id=48ee13f8&scoped=true"
import script from "./main.vue?vue&type=script&setup=true&lang=js"
export * from "./main.vue?vue&type=script&setup=true&lang=js"

import "./main.vue?vue&type=style&index=0&id=48ee13f8&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-48ee13f8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "48ee13f8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('48ee13f8', script)) {
    api.reload('48ee13f8', script)
  }
  
  module.hot.accept("./main.vue?vue&type=template&id=48ee13f8&scoped=true", () => {
    api.rerender('48ee13f8', render)
  })

}

script.__file = "src/views/verifyCode/main.vue"

export default script