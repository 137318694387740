<template>
  <div class="bg">
    <div class="content">
      <div class="con">
        <div class="h1"><strong>SUNWAY</strong></div>
        <div class="h2">Healthcare ePass</div>
        <div class="h3">Verify your phone number</div>
        <div class="f1">
          Enter your phone number to continue.
          <br />
          We’ll send a verification code that you can use to obtain your ePass.
        </div>
        <input type="tel" class="telnb" placeholder="Enter Your Phone Number" />
        <div v-if="isShowWarning" class="f2">
          The phone number is not yet registered with the organization. Please
          contact the administrator.
        </div>
        <a href="#" class="bt" @click.prevent="onClickOtp"><span>Send</span></a>
        <div class="f3">
          By clicking 【 Send 】, you agree to the
          <span class="blu">
            <a href="https://www.sunwaymedical.com/en/privacy-policy">
              Privacy Policy
            </a>
          </span>
          .
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'

  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const stationId = ref('')
  const isShowWarning = ref(false)

  const organizationId = computed(() => store.state.otp.organizationId)

  // Vuex action mapping
  const getStationInfo = (stationId) =>
    store.dispatch('otp/getStationInfo', stationId)
  const getOtp = (postBody) => store.dispatch('otp/getOtp', postBody)

  const onClickOtp = async () => {
    const tel = document.querySelector('.telnb').value
    if (!tel) {
      alert('Please enter your phone number')
      return
    }
    const postBody = {
      organizationId: organizationId.value,
      phoneNumber: tel,
    }

    const result = await getOtp(postBody)
    if (result === true) {
      router.push('/verify-code/inputPhoneNumber')
    } else {
      isShowWarning.value = true
      // router.push('/verify-code/inputPhoneNumber')
    }
  }

  onMounted(() => {
    stationId.value = route.query.stationId || '' //'DCF505C06E53'
    if (stationId.value) {
      getStationInfo(stationId.value)
    } else {
      alert('Invalid station ID')
    }
  })
</script>

<style lang="less" scoped>
  body {
    font-family: Arial, sans-serif;
    padding: 0;
    margin: 0;
  }
  a,
  img {
    outline: none;
  }
  input,
  textarea {
    outline: none;
  }
  .bg {
    position: relative;
    background: linear-gradient(to bottom, #f3f3f3, #ffffff);
  }
  .content {
    position: relative;
    width: 490px;
    margin: 0 auto;
    background: linear-gradient(to bottom, #f3f3f3, #ffffff);
  }
  .content::before,
  .content::after {
    position: relative;
    width: 100%;
    content: '';
    display: block;
  }
  .content::before {
    padding-bottom: 6.66%;
  }
  .content::after {
    padding-bottom: 9.33%;
  }
  .con {
    position: relative;
    width: 85.3%;
    margin: 0 auto;
    text-align: center;
    color: #595959;
  }
  .h1,
  .h2,
  .h3 {
    position: relative;
    display: block;
  }
  .h1 {
    font-size: 40px;
    font-weight: bolder;
    margin: 0 auto 14px;
  }
  .h2 {
    font-size: 30px;
    font-weight: bold;
    margin: 0 auto 35px;
  }
  .h3 {
    font-size: 24px;
    color: #505050;
    font-weight: bold;
    margin: 0 auto 35px;
  }
  .f1 {
    font-size: 20px;
    line-height: 25px;
    min-height: 95px;
    text-align: left;
    padding: 0 3px;
    margin: 0 auto 33px;
  }
  .f1 span {
    display: inline-block;
  }
  .f2 {
    font-size: 14px;
    line-height: 18px;
    min-height: 93px;
    text-align: left;
    color: #ed1d24;
    padding: 0 3px;
    margin: 0 auto;
  }
  .f3 {
    font-size: 14px;
    line-height: 20px;
    min-height: 93px;
    text-align: left;
    padding: 0 3px;
    margin: 0 auto;
  }
  .f4 {
    font-size: 16px;
    line-height: 25px;
    color: #575555;
    text-align: left;
    margin: 30px auto 0;
  }
  .telnb {
    position: relative;
    width: 100%;
    padding: 0 10px;
    border-radius: 8px;
    margin: 0 auto 10px;
    font-family: Arial, sans-serif;
    font-size: 20px;
    line-height: 50px;
    color: #575555;
    border: 1px solid #14224a;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  input:focus {
    border: 1px solid #ffa200;
  }
  ::placeholder {
    color: #c4c4c4;
  }
  .blu {
    color: #2f80ed;
  }
  .bt {
    position: relative;
    width: 100%;
    padding-bottom: 15.625%;
    border-radius: 10px;
    display: block;
    background-color: #8b2331;
    margin: 0 auto 10px;
    transition: all 0.5s;
  }
  .bt:hover {
    background-color: #b52639;
  }
  .bt span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }
  @media only screen and (max-width: 500px) {
    .content {
      width: 100%;
    }
  }
  @media only screen and (max-width: 450px) {
    .h1 {
      font-size: 8.88vw;
      margin: 0 auto 3.11vw;
    }
    .h2 {
      font-size: 6.6vw;
      margin: 0 auto 7.7vw;
    }
    .h3 {
      font-size: 5.33vw;
      margin: 0 auto 7.7vw;
    }
    .f1 {
      font-size: 4.4vw;
      line-height: 5.5vw;
      min-height: 21.1vw;
      padding: 0 0.6vw;
      margin: 0 auto 7.3vw;
    }
    .f2 {
      font-size: 3.11vw;
      line-height: 4vw;
      min-height: 20.6vw;
      padding: 0 0.6vw;
    }
    .f3 {
      font-size: 3.11vw;
      line-height: 4.4vw;
      min-height: 20.6vw;
      padding: 0 0.6vw;
    }
    .f4 {
      font-size: 3.55vw;
      line-height: 5.5vw;
      margin: 6.6vw auto 0;
    }
    .telnb {
      padding: 0 2.2vw;
      border-radius: 1.7vw;
      margin: 0 auto 2.2vw;
      font-size: 4.4vw;
      line-height: 11.11vw;
    }
    .bt {
      border-radius: 2.2vw;
      margin: 0 auto 2.2vw;
    }
    .bt span {
      font-size: 5.3vw;
    }
  }
  @media only screen and (max-width: 375px) {
    .h1 {
      font-size: 10.6vw;
      margin: 0 auto 3.72vw;
    }
    .h2 {
      font-size: 8vw;
      margin: 0 auto 9.33vw;
    }
    .h3 {
      font-size: 6.4vw;
      margin: 0 auto 9.33vw;
    }
    .f1 {
      font-size: 5.33vw;
      line-height: 6.6vw;
      min-height: 25.33vw;
      padding: 0 0.8vw;
      margin: 0 auto 8.8vw;
    }
    .f2 {
      font-size: 3.72vw;
      line-height: 4.8vw;
      min-height: 24.8vw;
      padding: 0 0.6vw;
    }
    .f3 {
      font-size: 3.72vw;
      line-height: 5.33vw;
      min-height: 24.8vw;
      padding: 0 0.8vw;
    }
    .f4 {
      font-size: 4.26vw;
      line-height: 6.6vw;
      margin: 8vw auto 0;
    }
    .telnb {
      padding: 0 2.6vw;
      border-radius: 2.13vw;
      margin: 0 auto 2.6vw;
      font-size: 5.33vw;
      line-height: 13.33vw;
    }
    .bt span {
      font-size: 6.4vw;
    }
  }
</style>
